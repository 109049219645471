import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { JbdCoreUserEventService } from '@core/services/user-event/user-event.service';
import { JbdCoreUserService } from '@core/services/user/user.service';
import { IJbdUser } from '@core/shared/misc/interfaces/user.interface';
import { JbdUiLayoutService } from '../../shared/layout/layout.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { JbdUiDropdownComponent } from '../../../../elements/dropdown/dropdown.component';
import { TranslocoModule } from '@jsverse/transloco';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'jbd-ui-layout-sidenav-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FlexLayoutModule,
    JbdUiDropdownComponent,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    NgTemplateOutlet,
    TranslocoModule,
  ],
})
export class JbdUiLayoutSidenavUserActionsComponent
  implements OnDestroy, OnInit
{
  private ngUnsubscribe = new Subject<void>();
  public menuIsOpen = false;
  public userProfileFilled = false;

  @Input() public user!: IJbdUser;

  constructor(
    private cdRef: ChangeDetectorRef,
    private userEventService: JbdCoreUserEventService,
    public userService: JbdCoreUserService,
    public layoutService: JbdUiLayoutService,
    public router: Router
  ) {}

  public ngOnInit() {
    this.handleProfileUpdates();
    this.subscribeToProfileUpdates();
  }

  private subscribeToProfileUpdates(): void {
    this.userEventService
      .onUpdate()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: this.handleProfileUpdates.bind(this),
      });
  }

  private handleProfileUpdates(): void {
    this.userProfileFilled =
      this.userService.hasUserAttribute('userProfileFilled');
    this.cdRef.markForCheck();
  }

  public async logout(): Promise<void> {
    const loginRoute = await this.userService.logoutUser();

    this.userEventService.triggerLogout();
    this.layoutService.closeSidenav();
    void this.router.navigate([loginRoute]);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
