import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { IJbdUser } from '@core/shared/misc/interfaces/user.interface';
import { IJbdUserEvent } from '@core/shared/misc/interfaces/user-event.interface';
import { JbdCoreUserEventService } from '@core/services/user-event/user-event.service';
import { JbdCoreUserService } from '@core/services/user/user.service';
import { JbdUiLayoutService } from '../shared/layout/layout.service';
import { Router } from '@angular/router';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JbdUiLayoutSidenavUserActionsComponent } from './user-actions/user-actions.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@jsverse/transloco';
import { NgComponentOutlet, NgIf, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'jbd-ui-layout-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  standalone: true,
  imports: [
    JbdUiLayoutSidenavUserActionsComponent,
    FlexLayoutModule,
    TranslocoModule,
    NgIf,
    NgComponentOutlet,
    NgOptimizedImage,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbdUiLayoutSidenavComponent implements OnDestroy, OnInit {
  private ngUnsubscribe = new Subject<void>();
  public sidenavListComponent?: Type<any>;
  public user!: IJbdUser | null;
  public userProfileFilled = false;

  @ViewChild('sidenavList', { read: ViewContainerRef })
  public sidenavList?: ElementRef;

  constructor(
    private cdRef: ChangeDetectorRef,
    private layoutService: JbdUiLayoutService,
    private router: Router,
    private userEventService: JbdCoreUserEventService,
    public userService: JbdCoreUserService
  ) {}

  public ngOnInit(): void {
    this.handleUserInit();
    this.subscribeToUserEvent();
  }

  private handleUserInit(): void {
    if (!this.userService.isLoggedIn()) {
      this.userEventService.triggerLogout();
    } else {
      this.setUserInfo(this.userService.getUser());
    }
  }

  private subscribeToUserEvent(): void {
    merge(
      this.userEventService.onLogin(),
      this.userEventService.onUpdate(),
      this.userEventService.onLogout()
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userEvent: IJbdUserEvent | null) =>
        this.setUserInfo(userEvent?.data ?? null)
      );
  }

  private setUserInfo(user: IJbdUser | null): void {
    if (!user) {
      this.userService.clearUser();
    }

    this.user = user;
    this.userProfileFilled =
      this.userService.hasUserAttribute('userProfileFilled');
    this.sidenavListComponent = this.layoutService.sidenavListComponentRef;
    this.cdRef.detectChanges();
  }

  public async redirectUser(): Promise<void> {
    await this.router.navigate([this.userService.getUserRoute()]);
    this.layoutService.closeSidenav();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
