import { Inject, Injectable } from '@angular/core';
import { JbdCoreUserService } from '@core/services/user/user.service';
import { JbdCoreRedirectService } from '@core/services/redirect/redirect.service';
import { IAppDetails } from '@core/shared/misc/interfaces/app-details.interface';
import { JbdCoreAuthService } from '@core/services/auth/auth.service';
import { JbdCoreDataService } from '@core/services/data/data.service';

@Injectable({
  providedIn: 'root',
})
export class JbdUserService extends JbdCoreUserService {
  constructor(
    @Inject('environment') environment: { appDetails: IAppDetails },
    authService: JbdCoreAuthService,
    coreDataService: JbdCoreDataService,
    private redirectService: JbdCoreRedirectService
  ) {
    super(environment, authService, coreDataService);
  }

  public get partnerId(): string {
    return this.getUser()?._embedded?.partner?.[0].id ?? '';
  }

  public get partnerNumber(): string {
    return this.getUser()?._embedded?.partner?.[0]?.partnerNumber ?? '';
  }

  public override getUserRoute(): string | undefined {
    const user = this.getUser();

    if (!this.isLoggedIn() || !user?.enabled || !user?.isPartner) {
      void this.logoutUser();

      return this.environment.appDetails.urls?.login;
    }

    return this.hasUserAttribute('userProfileFilled')
      ? this.redirectService.getRedirectUrl()
      : this.environment.appDetails.urls?.onboarding;
  }
}
