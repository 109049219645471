import { BehaviorSubject, Observable } from 'rxjs';
import { IJbdLocationListItem } from '../../misc/interfaces/location.interface';
import { Injectable } from '@angular/core';
import { JbdDataService } from '../data/data.service';
import { JbdUserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class JbdLocationsService {
  private locations = new BehaviorSubject<IJbdLocationListItem[] | undefined>(
    undefined
  );
  private locations$ = this.locations.asObservable();

  constructor(
    private dataService: JbdDataService,
    private userService: JbdUserService
  ) {}

  public setLocations(locations: IJbdLocationListItem[] | undefined): void {
    this.locations.next(locations);
  }

  public onLocationsUpdate(): Observable<IJbdLocationListItem[] | undefined> {
    return this.locations$;
  }

  public loadLocations(): void {
    if (!this.userService.isLoggedIn()) {
      return;
    }

    this.dataService.getLocations().subscribe({
      next: (locations: IJbdLocationListItem[]) => this.setLocations(locations),
    });
  }

  public clearLocations(): void {
    this.setLocations(undefined);
  }
}
